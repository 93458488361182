import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Header from "../components/Header/index.js"
import SEO from "../components/seo"
import { withPreview } from "gatsby-source-prismic"

const FAQ = ({ data }) => {
  const section = data.prismicFaqPages.data
  const faqSections = data.allPrismicFaqPages.edges.map(n => n.node)
  const page = data.prismicFaqPages.uid
  const heroImage = section.hero_banner_image.fluid.src

  return (
    <Layout>
      <SEO
        title={section.seo_title}
        keywords={section.seo_keywords}
        description={section.seo_description}
      />
      <Header image={heroImage} title={section.hero_title.text} />
      <section className="uk-section">
        <div className="uk-container">
          <div
            className="uk-text-center"
            dangerouslySetInnerHTML={{ __html: section.section_title.html }}
          />
          <div
            style={{ border: "1px solid #ccc" }}
            className="uk-width-1-1 uk-flex uk-flex-row uk-flex-wrap uk-flex-center uk-margin-medium uk-padding-small uk-border-pill uk-visible@m"
          >
            {faqSections.map((item, i) => (
              <Link
                key={item.uid}
                to={`/technical-center/faq/${item.uid}`}
                style={{
                  margin: "0 5px",
                }}
                className={`uk-button uk-button-large ${
                  item.uid === page ? "faq-current-section" : "uk-button-primary"
                } uk-border-pill`}
              >
                {item.data.section_name}
              </Link>
            ))}
          </div>

          <div className="uk-hidden@m  uk-flex uk-flex-center uk-margin-large">
            <button
              className="uk-position-relative uk-button uk-button-large uk-button-primary uk-border-pill"
              type="button"
              style={{ color: "white" }}
            >
              <span className="uk-position-right uk-position-small" data-uk-icon="chevron-down" />
              <span className="uk-h5 uk-margin-remove-vertical uk-padding-remove uk-margin-right uk-light">
                {faqSections.filter(f => f.uid === page).map(section => section.data.section_name)}
              </span>
            </button>
            <div data-uk-dropdown="mode: click; pos: bottom">
              <ul className="uk-nav uk-dropdown-nav">
                {faqSections.map((item, i) => (
                  <li key={item.uid}>
                    <Link to={`/technical-center/faq/${item.uid}`}>{item.data.section_name}</Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="uk-container">
          <p
            className="uk-h4 uk-margin-medium-top"
            dangerouslySetInnerHTML={{ __html: section.section_description.html }}
          />
          <ul className="uk-list-divider uk-list uk-margin-top">
            {section.faq_list.map((item, i) => (
              <li
                key={`faq-${i}`}
                style={{ paddingTop: "16px", paddingBottom: "16px" }}
                dangerouslySetInnerHTML={{ __html: item.faq_item.html }}
              />
            ))}
          </ul>
        </div>
      </section>
    </Layout>
  )
}

export default withPreview(FAQ)
export const faqDetailQuery = graphql`
  query faqDetailQuery($uid: String) {
    prismicFaqPages(uid: { eq: $uid }) {
      uid
      data {
        seo_title
        seo_keywords
        seo_description
        page_title
        hero_title {
          html
          text
        }
        hero_banner_image {
          fluid(maxWidth: 1400) {
            ...GatsbyPrismicImageFluid_noBase64
          }
        }
        section_title {
          html
        }
        section_description {
          html
        }
        faq_list {
          faq_item {
            html
          }
        }
      }
    }
    allPrismicFaqPages {
      edges {
        node {
          uid
          data {
            page_title
            section_name
          }
        }
      }
    }
  }
`
